import { adState } from '../adState';

// Find all ads using QuerySelectorAll (QSA).
// Compatability-note: This should work in IE8+ and all other browsers,
// and it allows us to not rely on jQuery. Not having jQuery means we
// could place this inlined in <head> if we wanted to..
export function getAds() {
  const ads = Array.from(
    document.querySelectorAll('div[data-ad]:not([data-ad-ignore])'),
  ).filter((ad) =>
    adState.getVisibleAds().includes(ad.getAttribute('data-ad')),
  );

  return ads;
}
