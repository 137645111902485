import { getBreakpoint } from './getBreakpoint';
import tv2Ads from '../tv2ads/ad-config';

export function getFetchMargin() {
  const {
    lazyLoad: { fetchMarginPercent, mobileScaling },
  } = tv2Ads;

  let fetchMargin = fetchMarginPercent;

  /* 'mobile' vs 'desktop' */
  const getDeviceType = () => {
    return ['base', 'small'].indexOf(getBreakpoint()) >= 0
      ? 'mobile'
      : 'desktop';
  };
  const deviceType = getDeviceType();

  // need to set the fetchMargin with mobileScaling as well
  if (deviceType === 'mobile') {
    fetchMargin *= mobileScaling;
  }

  return { fetchMargin, fetchMarginPercent, mobileScaling };
}
