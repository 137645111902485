export function getGoogleAdsLog() {
  const { utils } = window.tv2;

  if (!utils) {
    // eslint-disable-next-line no-console
    console.error('TV2 Utils not available');
  }
  const googleAdsLog = utils.setupLog('💰 Google ads');

  return googleAdsLog;
}
