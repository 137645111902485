import { getFetchMargin } from './getFetchMargin';
import { isInViewport } from './isInViewPort';

export function findAdsInViewPort() {
  const elements = document.querySelectorAll(
    'div[data-ad]:not([data-ad-ignore])',
  );
  const visibleElements = [];

  // Find specific elements, even if they are not in the viewport
  const specialAds = [
    'topbanner-1',
    'skyscraperleft-1',
    'skyscraperright-1',
    'topbanner-2',
    'skyscraperleft-2',
    'skyscraperright-2',
  ];

  elements.forEach((elem) => {
    const ad = elem.getAttribute('data-ad');

    const { fetchMargin } = getFetchMargin(); // Adjust this value to control how early the check fires

    // Check if element is in the viewport horizontally and vertically
    const isAdInViewport = isInViewport(elem, fetchMargin);

    // Include elements in the viewport or with special ad values
    if (isAdInViewport || specialAds.includes(ad)) {
      elem.setAttribute('data-ad-loaded', 'true');
      visibleElements.push(ad);
    }
  });

  return visibleElements;
}
