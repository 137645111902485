import { findAdsInViewPort } from '../utils/findAdsInViewPort';

/**
 * State variables
 */
let visibleAds = [];
let googlePpid;

/**
 * Ad state manager. This object controls access to the state variables used across the google ads implementation.
 * This is used intermediately while refactoring the ads solution.
 */
const adState = {
  initializeAdState() {
    visibleAds = findAdsInViewPort();
  },
  setVisibleAds(newVisibleAdsValue) {
    visibleAds = newVisibleAdsValue;
  },
  getVisibleAds() {
    return visibleAds;
  },
  getGooglePpid() {
    return googlePpid;
  },
  setGooglePpid(newId) {
    googlePpid = newId;
  },
};

Object.freeze(adState);
export { adState };
