export const deciderAds = ['feature-1', 'feature-2'];

export function isDeciderElem(elem) {
  try {
    if (elem.hasAttribute('data-exclude-decider')) {
      return true;
    }

    if (deciderAds.includes(elem.getAttribute('data-ad'))) {
      return true;
    }
    return false;
  } catch (_) {
    return false;
  }
}
