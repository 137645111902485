export function isInViewport(elem, offset = 200) {
  const rect = elem.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  // Check if the top of the element is within the viewport or above it by the offset
  const topInView = rect.top - offset <= windowHeight;

  // Check if the bottom of the element is not above the viewport
  const bottomInView = rect.bottom >= 0;

  return topInView && bottomInView;
}
