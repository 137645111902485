import { getGoogleTag } from '../../utils/getGoogleTag';

export function loadAdScript(consent) {
  const googletag = getGoogleTag();
  const { utils } = window.tv2;

  if (!consent) {
    // Load up the GAM script from Google with limited ads
    googletag.cmd.push(() => {
      // Custom targeting param for use in ad manager
      googletag.pubads().setTargeting('ltd', '1');
    });
    utils.loadScript('https://pagead2.googlesyndication.com/tag/js/gpt.js');
  } else {
    // Load up the standard GAM script from Google
    utils.loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
  }
}
