import extractMetaTags from '../utils/extract-metatags';
import { adPath } from '../utils/adPath';
/*
 * This module is in charge of finding all of our tracking parameters... These
 * can come from either meta-tags, TV 2 Login, etc...
 *
 * We have a lazy approach to external tracking parameters: We will fetch them
 * on subsequent page-loads, but for unknown users we don't wait untill we have
 * found the external tracking info before fetching ads.
 */

// React way of getting tracking params from existing dataLayer
function getTrackingFromDataLayer() {
  const { dataLayer = {} } = window;
  const { article = {} } = dataLayer;
  const isArticle = !!article.id;

  // Extract params from the datalayer, either the article or the root
  const {
    site,
    section,
    primarySection,
    secondarySections,
    pageType,
    tema,
    id,
    series,
    event,
    // eslint-disable-next-line camelcase
    variant: article_type,
    videoArticle,
    embeds = [],
    tv2context,
  } = isArticle ? article : dataLayer;

  // eslint-disable-next-line camelcase
  const has_top_video = videoArticle ? '1' : null;

  // eslint-disable-next-line camelcase
  const has_embedded_video = embeds.includes('EmbedVideo') ? '1' : null;

  // eslint-disable-next-line camelcase
  const has_video = has_top_video || has_embedded_video ? '1' : null;

  // Add basic tracking params
  let dataLayerTracking = {
    section: primarySection || section,
    secondarySections,
    pageType,
    tema,
    site,
    series,
    event,
    experimentVariant: dataLayer.experimentVariant,
  };

  // If on an article, add article specific params
  if (isArticle) {
    dataLayerTracking = {
      ...dataLayerTracking,
      // eslint-disable-next-line camelcase
      page_type: 'content',
      // eslint-disable-next-line camelcase
      content_type: 'article',
      // eslint-disable-next-line camelcase
      article_type,
      // eslint-disable-next-line camelcase
      has_top_video,
      // eslint-disable-next-line camelcase
      has_video,
      // eslint-disable-next-line camelcase
      has_embedded_video,
      // eslint-disable-next-line camelcase
      content_id: id,
      // eslint-disable-next-line camelcase
      tv2context,
    };
  }

  // Strip undefined values
  return Object.keys(dataLayerTracking).reduce((acc, key) => {
    if (dataLayerTracking[key] !== undefined) acc[key] = dataLayerTracking[key];
    return acc;
  }, {});
}

export const getTracking = () => {
  let trackingParameters = {};

  // Get tracking params from t2video_ metatags, and add dfpAdPath if applicable
  const t2videoMetaTags = extractMetaTags(/^t2video_(.+)$/);
  const { adunit_name: adUnitName } = t2videoMetaTags;
  if (adUnitName) {
    const dfpAdPath = `/${adPath(adUnitName).join('/')}`;
    trackingParameters = { ...trackingParameters, dfpAdPath };
  }

  // Get tracking params from the deta layer
  const trackingFromDataLayer = getTrackingFromDataLayer();

  trackingParameters = {
    ...trackingParameters,
    ...trackingFromDataLayer,
  };

  // set a url, either from link tag or dfp or the current location
  const canonicalLink = document.querySelector('link[rel=canonical]');
  const canonicalUrl = canonicalLink && canonicalLink.getAttribute('href');
  trackingParameters = {
    ...trackingParameters,
    url: canonicalUrl || trackingParameters.url || window.location.href,
  };
  const log = window.tv2?.utils?.setupLog('🕵️ Tracking');
  if (log) {
    log.info('Got tracking params for page', trackingParameters);
  }
  return trackingParameters;
};
