import { getParentWildcardDomain } from '../../utils/getParentDomain';

const advertisementIdKey = 'tv2PBJSSessionKey';
const advertisementTraceId = 't2aid';

// TODO: The TRACEID_TIMEOUT is set to 1 second, but it used to be set to 8 seconds for some reason.
// This should be investigated and the timeout should be set to the correct value.
const TRACEID_TIMEOUT = 1000;
const HAS_CONSENT_TIMEOUT = 8000;

// https://gist.github.com/gordonbrander/2230317?permalink_comment_id=3752738#gistcomment-3752738
function generateId() {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = '';
  for (let i = 0; i < array.length; i += 1) {
    str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
  }
  return str;
}

const isLocalhost = window.location.host.includes('localhost:1400');

function loadAdvertisementId() {
  return new Promise((resolve) => {
    window.tv2messages.onceWithTimeout(
      'traceIds',
      isLocalhost ? 0 : TRACEID_TIMEOUT,
      (traceIds) => {
        const fromAuth0 = traceIds && traceIds[advertisementTraceId];
        const fromCookie = window.tv2.utils.getCookie(advertisementIdKey);
        const fromSession = window.sessionStorage.getItem(advertisementIdKey);
        resolve(fromAuth0 || fromCookie || fromSession || null);
      },
    );
  });
}

function setAdvertisementId(advertisementId, consent) {
  if (consent) {
    window.tv2.utils.setCookie(
      advertisementIdKey,
      advertisementId,
      '30 days',
      getParentWildcardDomain(window.location.hostname),
    );
  }
  window.sessionStorage.setItem(advertisementIdKey, advertisementId);
}

export async function getAdvertisementId() {
  let advertisementId = await loadAdvertisementId();
  return new Promise((resolve) => {
    const log = window.tv2.utils.setupLog('🆔 Advertiser ID');

    window.tv2messages.onceWithTimeout(
      'hasConsent',
      HAS_CONSENT_TIMEOUT,
      (consent) => {
        if (!advertisementId) {
          log.info('Not found, creating new ID.');
          advertisementId = generateId();
        }

        setAdvertisementId(advertisementId, consent);

        log.info(`Using ${advertisementId}`);
        resolve(advertisementId);
      },
    );
  });
}
