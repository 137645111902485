import { getGoogleTag } from '../../utils/getGoogleTag';
import { adState } from '../adState';
import { getGoogleAdsLog } from './getGoogleAdsLog';
import { adPath } from '../../utils/adPath';
import getAdSizeMappings, { getDefaultSize } from '../ad-size-mapping';
import tv2Ads from '../ad-config';
import { requestBids } from '../../relevant';

/**
 * Intersect ads in viewport and load them
 */
export function handleAdIntersection(entries, tracking, advertisementId) {
  const log = getGoogleAdsLog();
  const googletag = getGoogleTag();
  const { adPathPrefix, adPositionPrefix } = tv2Ads;

  const newSlotsRegistered = [];
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const elem = entry.target;
      const ad = elem.getAttribute('data-ad');
      /**
       * This check is to prevent multiple ad loads on the same element
       * Prevents Interscroll ads to get overriden by a Google ad
       */
      if (elem.children.length > 0) {
        log.info('Ad slot has child element', elem.children[0]);
        return;
      }

      // First check if the slot is already defined in GPT
      const existingSlot = googletag
        .pubads()
        .getSlots()
        .find((slot) => slot.getSlotElementId() === ad);

      // const visibleAds = adState.getVisibleAds();

      // Only proceed if:
      // 1. The ad isn't already in visibleAds array
      // 2. The slot isn't already defined in GPT
      // 3. The element doesn't have data-ad-loaded="true"
      if (
        !adState.getVisibleAds().includes(ad) &&
        !existingSlot &&
        elem.getAttribute('data-ad-loaded') !== 'true'
      ) {
        log.info('Creating ad slot on element', ad);

        const path = `/${adPath(ad, adPathPrefix, adPositionPrefix).join('/')}`;
        const sizes = getAdSizeMappings(elem);
        const defaultSize = getDefaultSize(sizes);

        try {
          const slot = googletag.defineSlot(path, defaultSize, ad);
          if (slot) {
            slot.defineSizeMapping(sizes).addService(googletag.pubads());

            googletag.display(slot);
            newSlotsRegistered.push(slot);

            const newVisibleAds = [...adState.getVisibleAds(), ad];
            adState.setVisibleAds(newVisibleAds);

            elem.setAttribute('data-ad-loaded', 'true');
          }
        } catch (error) {
          log.error(`Failed to define slot for ${ad}`, error);
        }
      }

      // Refresh new slots so they can fetch ads and render
      if (newSlotsRegistered.length > 0) {
        log.info('Refreshing new ad slots', { newSlotsRegistered });
        // Update relevant prebid bids for new slots
        requestBids({
          tracking,
          advertisementId,
          onAuctionDone: () => googletag.pubads().refresh(newSlotsRegistered),
        });
      }

      log.groupEnd();
    }
  });
}
